/**
 * 1. 注意下面的 config 會暴露在 browser 請勿把內部 API key 等密碼放在此
 * 2. 下面的 config 是 build time only，需要重新 next build 才會生效 (改 process.env 沒用)
 */
const config = {
  env: process.env.NODE_ENV,
  myhost: process.env.MY_HOST,
  coreApiEndpoint: process.env.CORE_API_ENDPOINT,
  imageBaseUrl: process.env.IMAGE_BASE_URL,
  cookieDomain: process.env.COOKIE_DOMAIN,
  appUrl: process.env.APP_URL,
  appStaticUrl: process.env.STATIC_URL,
  appImgUrl: process.env.WP_CDN_IMAGE_URL,
  appUrlFE: process.env.MY_HOST,
  imageAssetUrl: process.env.IMAGE_ASSET_URL,
  downloadAppUrl: process.env.DOWNLOAD_APP_URL,
  shaperUrl: process.env.SHAPER_URL,
  fbAppId: process.env.FB_APP_ID || '1064594546929511',
  mapboxApiToken: process.env.MAPBOX_API_TOKEN,
  fbShareDefaultUrl: 'https://www.popdaily.com.tw/landingpage/img/fbshare.png',
  amplitudeApiKey: process.env.AMPLITUDE_API_KEY,
  amplitudeApiKeyV2: process.env.AMPLITUDE_API_KEY_V2,
  amplitudeEnabled: process.env.AMPLITUDE_ENABLED,
  mixpanelToken: process.env.MIXPANEL_TOKEN,
  mixpanelEnabled: process.env.MIXPANEL_ENABLED,
  blueKaiID: process.env.BLUEKAI_ID,
  fortuneDeepLink: process.env.FORTUNE_DEEP_LINK,
  ampAnalyticsConfig: process.env.AMP_ANALYTICS_CONFIG,
  sentry: {
    enabled: process.env.SENTRY_ENABLED,
    dsn: process.env.SENTRY_DSN,
  },
  popdailyEnv: process.env.POPDAILY_ENV,
  gitSha: process.env.GIT_SHA,
  graphqlServerUri: process.env.GRAPHQL_SERVER_URI,
  graphqlClientUri: process.env.GRAPHQL_CLIENT_URI,
  mockServerEnabled: process.env.MOCK_SERVER_ENABLED,
  oneSignalAppId: process.env.ONESIGNAL_APP_ID,
  oneSignalEnabled: process.env.ONESIGNAL_ENABLED,
  oneSignalSafariWebId: process.env.ONESIGNAL_SAFARI_WEB_ID,
  recommendedArticlesEnabled: process.env.RECOMMENDED_ARTICLES_ENABLED,
  popularArticlesEnabled: process.env.POPULAR_ARTICLES_ENABLED,
  articleIntersectionEnabled: process.env.ARTICLE_INTERSECTION_ENABLED,
  articleTagsEnabled: process.env.ARTICLE_TAGS_ENABLED,
  gtm: {
    enabled: process.env.GTM_ENABLED,
    src: process.env.GTM_SRC,
  },
}

export default config
